<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb text-dark" style="margin: auto;">
                <li class="breadcrumb-item active" aria-current="page">首頁</li>
            </ol>
        </nav>
        <hr class="w-100">
    </div>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
    }
};
</script>
